import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "@/components/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

type Props = {
  icon?: React.ReactNode;
  title?: string;
  body?: string;

  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;

  handleSuccess?: () => Promise<any>;
  successText?: string;
  isLoadingSuccess?: boolean;

  handleDestroy: (() => Promise<void>) | undefined | null;
  destroyText: string | undefined | null;
  destroyDescription?: string;
  isLoadingDestroy?: boolean;
  isDisableForAdminOnly?: boolean;

  initialFocus?: React.RefObject<HTMLElement>;

  isSuccessDisabled?: boolean;
  shouldHideClose?: boolean;

  leftRef?: React.RefObject<HTMLElement | null>;
  rightRef?: React.RefObject<HTMLElement | null>;
};

export const formHelper = (form: any, onSubmit: any) => async () => {
  let error = null;
  await form.handleSubmit(onSubmit, () => {
    error = true;
  })();
  if (error) {
    throw new Error("Error");
  }
};

export default function ModalBase({
  icon,
  title,
  body,
  open,
  setOpen,
  children,
  handleSuccess,
  successText,
  isSuccessDisabled = false,
  handleDestroy,
  destroyText,
  destroyDescription,
  isDisableForAdminOnly = false,
  shouldHideClose = false,
}: Props) {
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [animateSuccess, setAnimateSuccess] = useState(false);
  const [isLoadingDestroy, setIsLoadingDestroy] = useState(false);
  const [destroyOpen, setDestroyOpen] = useState(false);

  const handleSuccessLocally = async () => {
    console.log("handleSuccessLocally");
    setIsLoadingSuccess(true);
    try {
      await handleSuccess!();
    } catch (e) {
      setIsLoadingSuccess(false);
      return;
    }
    setIsLoadingSuccess(false);
    setAnimateSuccess(true);
    setTimeout(() => {
      setOpen(false);
      setTimeout(() => {
        setAnimateSuccess(false);
      }, 250);
    }, 250);
  };

  const handleDestroyLocally = async () => {
    setDestroyOpen(true);
  };

  const handleDestroyResponse = async (approved: boolean) => {
    setDestroyOpen(false);
    if (approved) {
      setIsLoadingDestroy(true);
      await handleDestroy!();
      setIsLoadingDestroy(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && open && handleSuccess) {
        handleSuccessLocally();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, handleSuccess]);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            <div className="flex gap-3 items-center">
              {icon && (
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-srPrimary10">
                    {icon}
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-1 w-full">
                {title && <DialogTitle>{title}</DialogTitle>}
                {body && <DialogDescription>{body}</DialogDescription>}
              </div>
            </div>
          </DialogHeader>
          <div className={(handleSuccess || handleDestroy) && `pb-4`}>
            {children}
          </div>
          {(handleSuccess || handleDestroy) && (
            <DialogFooter className="bg-gray-200 -mx-6 -mb-6 px-6 py-4 flex justify-between">
              {handleDestroy && destroyText && (
                <Button
                  variant={destroyText === "Back" ? "secondary" : "ghost"}
                  onClick={handleDestroyLocally}
                  disabled={isDisableForAdminOnly}
                  className={destroyText ? "" : "invisible"}
                >
                  {destroyText}
                </Button>
              )}
              {handleSuccess && (
                <Button
                  isPrimary
                  disabled={isSuccessDisabled}
                  onClick={handleSuccessLocally}
                  isLoading={isLoadingSuccess}
                  playSuccessAnimation={animateSuccess}
                >
                  {successText}
                </Button>
              )}
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={destroyOpen} onOpenChange={setDestroyOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogDescription>
              {destroyDescription || "Are you sure?"}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={() => handleDestroyResponse(false)}>Cancel</Button>
            <Button isPrimary onClick={() => handleDestroyResponse(true)}>
              Continue
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
